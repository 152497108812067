import isReigniteResultsBlacklisted from "./isReigniteResultsBlacklisted";
import isReigniteResultsWhitelisted from "./isReigniteResultsWhitelisted";
import { useFirebaseConfig } from "./remote-config";

export default function useReigniteResults(athlinksEventId: number, isEventLinked: boolean) {
  const firebaseConfig = useFirebaseConfig()
  const allowReigniteResults = firebaseConfig.config.useReigniteResults?.asBoolean() ?? false

  return allowReigniteResults
    && isReigniteResultsWhitelisted(firebaseConfig, athlinksEventId, isEventLinked)
    && !isReigniteResultsBlacklisted(firebaseConfig, athlinksEventId)
}