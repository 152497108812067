import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { singletonHook } from 'react-singleton-hook'
//TODO: ideally these should not be called here; rather, logged-in profile and image should be passed as props
import {
  getTokenRacerId,
  isLoggedIn,
} from '../../utils/isLoggedIn';
import {AthlinksLogo} from '../../components/shared/AthlinksLogo';
import {SearchTab} from '../home/SearchTab'
import LogoFlame from '../../components/shared/AthlinksLogoFlame';
import { Link } from 'react-router-dom';
import {_header} from './styles';
import {LiveRaceHeader} from '../../components/shared/LiveRaceHeader';
import { inIframe } from '../../utils/inIframe';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {colors} from '../../shared/styles';
import {
  getSearchTerm,
  setTermValue
} from '../../data/SearchTerm';
import {HeaderSearchField} from '../../components/shared/HeaderSearchField';
import {connect} from 'react-redux';
import {EVENT_CONTEXT} from '../../data/SearchContext';
import SearchFilters from './SearchFilters';
import { getSearchContext } from '../../data/allResultsStreams';
import * as bowser from 'bowser';
import onClickOutside from 'react-onclickoutside';
import { getAthleteStream } from '../../data/AthleteStreams';
import { configs } from '../../configs';
import InfoIcon from '@material-ui/icons/Info';
import { useFirebaseConfig } from '../../lib/firebase/remote-config';

export const LoggedOutLinks = ({isMobile, t}) => {
  const currentItemStyle = (isMobile) ? _header.menuItemMobile : _header.navButton;
  const linkProps = inIframe() ? { target: '_blank' } : {};

  return (
    <div style={_header.actionContainer(isMobile)} className="header-links">
      {isMobile
        ? <Link to='/' id="homepage-link" style={currentItemStyle(false)}>{t('Home')}</Link>
        : null
      }
      <a style={currentItemStyle(false)}
         id="sign-in"
         onClick={() => trackGoogleEvent('Authentication', 'Click', 'Sign In')}
         href={window.kc.createLoginUrl()}
         {...linkProps}
      >
        {t('Sign In')}
      </a>
      <a style={currentItemStyle(true)} id="signup-link"
         onClick={() => trackGoogleEvent('Authentication', 'Click', 'Sign Up')}
         href={window.kc.createRegisterUrl()}
         {...linkProps}
      >
        {t('Sign Up')}
      </a>
    </div>
  )
};

const LoggedInLinksComponent = ({isMobile = false, t, athlete}) => {
  const [popupVisible, setPopupVisible] = useState(false)
  const [profPicUrl, setProfPicUrl] = useState(configs.cdnUrl + '/images/default-athlete-blue.jpg')
  const loggedInId = getTokenRacerId();
  if (athlete && athlete.athlete) athlete = athlete.athlete

  useEffect(() => {
    if (athlete?.LogoUrls?.Thumb) {
      // console.log('CHECKING Prof Pic', athlete?.LogoUrls?.Thumb)
      fetch(athlete?.LogoUrls?.Thumb, {method: 'HEAD'})
        .then((r) => {
          // console.log('PROFILE PIC CHECK', r.status, r)
          if (r.ok) {
            // console.log('SETTING PROFILE PIC', athlete?.LogoUrls?.Thumb)
            setProfPicUrl(athlete?.LogoUrls?.Thumb)
          }
          else {
            // console.log('NOT SETTING PROFILE PIC', athlete?.LogoUrls?.Thumb)
          }
        })
        .catch(() => {
          // console.error('ERROR CHECKING PROF PIC', athlete?.LogoUrls?.Thumb)
        })
    }
  }, [athlete])

  const handleClick = () => {
    setPopupVisible(!popupVisible)
  };

  let profileUrl = window.kc.createProfileUrl ? window.kc.createProfileUrl() : window.kc.createAccountUrl()

  return (
    <div className="popover-container" style={ _header.actionContainer(isMobile) }>
      {isMobile &&
      <div id="menu-items">
        <Link
          to={`/athletes/${loggedInId}`}
          style={_header.menuItemMobile(false)}
        >
          {t('Profile')}
        </Link>
        <a
          href={profileUrl}
          style={_header.menuItemMobile(false)}
          target="account-settings"
        >
          {t('Settings')}
        </a>
        <a
          href={window.kc.createLogoutUrl()}
          style={_header.menuItemMobile(false)}
        >
          {t('Sign Out')}
        </a>
      </div>
      }
      {profPicUrl && !isMobile &&
      <div style={_header.profPicContainer} id="menu-items">
        <img
          onClick={handleClick}
          style={_header.profPicIMG}
          src={profPicUrl}
          alt={''}
        />
        { popupVisible && <LoggedInMenu t={t} loggedInId={loggedInId} handleClickOutside={handleClick} /> }
      </div>
      }
    </div>
  );
}

class Menu extends React.Component {
  render() {
    const profileUrl = window.kc.createProfileUrl ? window.kc.createProfileUrl() : window.kc.createAccountUrl()
    const { t, loggedInId } = this.props;
    return (
      <div id="menu-popover" className="popover" style={_header.dropMenu(true)}>
        <Link
          id="athlete-profile-link"
          to={`/athletes/${loggedInId}`}
          style={_header.dropMenuItem}>
          {t('Profile')}
        </Link>
        <a
          id="edit-profile"
          href={profileUrl}
          style={_header.dropMenuItem}
        >
          {t('Settings')}
        </a>
        <a
          id="sign-out-link"
          href={window.kc.createLogoutUrl()}
          style={_header.dropMenuItem}>
          {t('Sign Out')}
        </a>
      </div>
    )
  }
}

const LoggedInMenu = onClickOutside(Menu)

export const LoggedInLinks = withTranslation()(connectStream({
  athlete: () => getAthleteStream(getTokenRacerId())
})(LoggedInLinksComponent));

const setSearchTermInStream = ({target: {value}}) => {
  setTermValue(value);
};

const mapStateToPropsHeader = (state) => ({
  context: state.search.context
});

class HomePageHeaderComponent extends React.Component {
  render() {
    const {
      allowSitewideSearch,
      athlete,
      context,
      filters,
      hasLiveBanner,
      history,
      isMobile,
      liveEventURL,
      onSearchInput,
      searchTab,
      searchTerm,
      showHeaderFilters,
      showSearchBar,
      t,
      unifiedSearchMode
    } = this.props;

    const browserName = bowser.name.toLowerCase();
    const link = _header.logoLink(browserName === 'internet explorer');
    const navLeft = (browserName === 'internet explorer') ? _header.navLeftIE : _header.navLeft;
    const placeholderText = context === EVENT_CONTEXT ? t('Enter an event name') : t('Enter an athlete name');

    const onSearchChange = onSearchInput || setSearchTermInStream;

    return (
      <div>
        <SiteNotice />
        <nav style={_header.navContainer(showSearchBar)}>
          <div style={_header.topNav}>
            {
              // Left logo
            }
            <div style={navLeft}>
              <Link
                id='athlinks-logo'
                to={'/'}
                style={link}
              >
                {!showSearchBar ?
                  <AthlinksLogo fill={colors.white} showFullLogo={true}/> :
                  !isMobile ?
                    <AthlinksLogo fill={colors.white} showFullLogo={true}/> :
                    <LogoFlame fill={colors.blue}/>
                }
              </Link>
            </div>
            {
              // Search Input
              allowSitewideSearch && <div style={ _header.navCenter(isLoggedIn()) }>
              <div style={{display: 'flex'}}>
                {
                  showSearchBar &&
                  <SearchTab
                    styleMode={'dark'}
                    isMobile={isMobile}
                    searchTerm={searchTerm}
                  />
                }
                {
                  showSearchBar &&
                  <div style={{flex: '1 1 100%'}}>
                    <HeaderSearchField
                      id="header"
                      type='search'
                      placeholder={placeholderText}
                      onChange={onSearchChange}
                      isMobile={isMobile}
                      value={searchTerm}
                      context={context}
                      unifiedSearchMode={unifiedSearchMode}
                      style={_header.headerSearchField(showSearchBar)}
                    />
                  </div>
                }
              </div>
            </div>
            }
            {
              // Right-side links
            }
            <div style={_header.navRight}>
              {
                isLoggedIn() ?
                  <LoggedInLinks showSearchBar={showSearchBar}/> :
                  <LoggedOutLinks showSearchBar={showSearchBar} isMobile={isMobile} t={t}/>
              }
            </div>
          </div>
        </nav>
        {
          showHeaderFilters &&
          <nav style={ _header.filterNavContainer(showSearchBar) }>
            <SearchFilters activeContext={searchTab} filters={filters} history={history}/>
          </nav>
        }
        {
          hasLiveBanner &&
          <LiveRaceHeader
            isMobile={isMobile}
            liveEventURL={liveEventURL}
            isProfile={true}
            athlete={athlete}
            t={t}
          />
        }
      </div>
    );
  }
}

export const HomePageHeader = connect(mapStateToPropsHeader)(withTranslation()(
  connectStream({
    context: getSearchContext,
    searchTerm: getSearchTerm
  })(HomePageHeaderComponent)));

//
// Site notice
//

export const useSiteNotice = singletonHook(undefined, () => {
  const firebaseConfig = useFirebaseConfig().config
  const [message, setMessage] = React.useState(undefined)
  const [link, setLink] = React.useState(undefined)
  React.useEffect(() => {
    if (firebaseConfig?.siteNoticeMessage) {
      const siteNoticeMessage = firebaseConfig?.siteNoticeMessage.asString().trim()
      if (siteNoticeMessage.length) {
        setMessage(siteNoticeMessage)
        if (firebaseConfig.siteNoticeLink) {
          const siteNoticeLink = firebaseConfig.siteNoticeLink.asString().trim()
          if (siteNoticeLink.length) {
            setLink(siteNoticeLink)
          }
          else {
            setLink(undefined)
          }
        }
        else {
          setLink(undefined)
        }
      }
      else {
        setMessage(undefined)
        setLink(undefined)
      }
    }
    else {
      setMessage(undefined)
      setLink(undefined)
    }
  }, [setMessage, setLink, firebaseConfig])
  return {link, message}
})

export const SiteNotice = ({mobile = false}) => {
  const notice = useSiteNotice()
  return notice?.message ? (
    <div style={{
      backgroundColor: 'rgb(255, 244, 229)',
      color: 'rgb(102, 60, 0)',
      padding: 8,
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: mobile ? '0.75rem' : '1rem',
      position: 'relative',
      zIndex: 99999,
    }}><InfoIcon fontSize="inherit" />
      <span style={{marginLeft: 8}}>
        {notice.message} {notice.link && (<a
          href={notice.link}
          rel="noopener noreferrer"
          target="_blank" style={{color: 'rgb(102, 60, 0)', textDecoration: 'none', fontWeight: 'bold', display: mobile ? 'block' : 'inline'}}>Click here to read more.</a>)}
      </span>
    </div>
  ) : null
}