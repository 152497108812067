import { FirebaseConfig } from './remote-config'

export default function isReigniteResultsWhitelisted(firebaseConfig: FirebaseConfig, athlinksEventId: number, isEventLinked: boolean) {
  const raw = firebaseConfig.config.useReigniteResultsEventWhitelist?.asString() ?? ''
  const whitelistedEvents = raw.split(',').map(x => +x).filter(y => !!y)

  if (!whitelistedEvents.length) {
    return isEventLinked
  }

  return isEventLinked || whitelistedEvents.includes(athlinksEventId)
}