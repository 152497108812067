import {LoggedInLinks, LoggedOutLinks, SiteNotice} from '../../views/home/HomePageHeader';
import onClickOutside from 'react-onclickoutside';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SmartBanner from 'react-smartbanner';
import {withTranslation} from 'react-i18next';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import ClearIcon from 'react-icons/lib/md/clear';
import DownIcon from 'react-icons/lib/fa/angle-down';
import { setTermValue, getSearchTerm } from '../../data/SearchTerm';
import { SearchTab } from '../../views/home/SearchTab';
import LogoFlame from '../../components/shared/AthlinksLogoFlame';
import UpIcon from 'react-icons/lib/fa/angle-up';

import {_header} from '../../views/home/styles';
import {colors} from '../../shared/styles';
import {configs} from '../../configs';
import {HeaderSearchField} from './HeaderSearchField';
import { styles } from './styles';
import SearchFilters from '../../views/home/SearchFilters';
import {isLoggedIn} from '../../utils/isLoggedIn';

const LOGO_ID = 'mobile-logo'

/**
 * Class that renders header on mobile device.
 */
export class MobileHeaderComponent extends Component {

  static propTypes = {
    isMobile: PropTypes.bool,
    searchTerm: PropTypes.string,
    t: PropTypes.func
  };

  state = {
    showNavigation: false,
    showSearchField: true,
    showDropdown: false,
    closedOutside: false,
    contextFocused: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.masterId !== this.props.masterId) {
      this.setState({
        showDropdown: true,
        showSearchField: false
      });
    }
  }

  onSearchPage = () =>
    window.location.href.match(/search/)

  /**
   * Show or hide search field
   *
   * @param {boolean} showSearchField
   * @param showDropdown
   */
  displaySearchField = (showSearchField = true, showDropdown = false) =>
    this.setState({showSearchField, showDropdown});

  render() {
    let {
      allowSitewideSearch,
      filters,
      history,
      isMobile,
      onHomePage = false,
      onSearchInput,
      searchTab,
      searchTerm = '',
      showSearchTab,
      t,
      unifiedSearchMode
    } = this.props;

    const {
      showNavigation,
      showSearchField,
      searchFocused,
      contextFocused,
      closedOutside
    } = this.state;

    if (unifiedSearchMode) {
      onSearchInput = onSearchInput ? onSearchInput : setSearchTermInStream;
    }
    const logoStyle = {
      zIndex: showSearchField && (contextFocused || searchFocused) ? 1 : 11,
      paddingLeft: 10,
      transition: 'all 0.4s ease 0s',
      opacity: showSearchField && (contextFocused || searchFocused) ? 0 : 1
    }
    return (
      <div style={styles.mobileHeader.main}>
        <SiteNotice mobile={true}/>
        <SmartBanner title='Athlinks Mobile'/>
        <div className='row mx-0' style={styles.mobileHeader.logo}>
          <div className='col-2 pr-0' style={logoStyle}>
            <Logo
              isNavOpened={showNavigation}
              onClick={() => {
                if (closedOutside) {
                  this.setState({closedOutside: false})
                } else {
                  this.setState({showNavigation: true})
                }
              }}
            />
          </div>
          {allowSitewideSearch &&
          <div style={{
            display: 'flex',
            position: 'absolute',
            right: -10,
            width: (contextFocused || searchFocused) ? '100%' : '85%',
            transition: 'all 0.4s ease 0s'
          }}>
            {
              !onHomePage && 
              <div style={showSearchField
                ? styles.mobileHeader.searchContextStyle
                : styles.mobileHeader.searchContextStyleHidden
              }>
                <SearchTab
                  isMobile={isMobile}
                  onFocusChange={(focus, e) => {
                    const clickedOnSearch = e && e.target && e.target.parentElement && (e.target.parentElement.id === 'Layer_2' || e.target.id === 'Angle_Icon')
                    this.setState({contextFocused: focus, searchFocused: this.state.contextFocused && !clickedOnSearch})
                  }}
                  styleMode={'dark'}
                  searchTerm={searchTerm}
                />
              </div>
            }
            {
              !onHomePage &&
              <div className='col pl-0' style={styles.mobileHeader.searchContent(showSearchField)}>
                <div style={styles.mobileHeader.searchFieldStyle(showSearchField)}>
                  <HeaderSearchField
                    id='header'
                    style={{width: '100%', WebkitAppearance: 'none'}}
                    isMobile={true}
                    type='search'
                    onChange={onSearchInput}
                    onSearchFocus={(focus) => this.setState({
                      searchFocused: focus,
                      showNavigation: false
                    })}
                    unifiedSearchMode={unifiedSearchMode}
                    value={searchTerm}
                    forceFocus={false}
                  />
                </div>
              </div>
            }
            {
              !onHomePage &&
                <img
                  src={`${configs.bragiUrl}/svg/search-icon-white.svg`}
                  alt={''}
                  style={styles.mobileHeader.searchOpenStyle(showSearchField)}
                  onClick={() => this.displaySearchField(true, false)}
                />
            }
            {
              !onHomePage && showSearchField &&
              <div style={styles.mobileHeader.closeBacking}>
                <ClearIcon size={30}
                          color={colors.white}
                          style={styles.mobileHeader.clearIcon}
                          onClick={() => this.displaySearchField(false, true)}
                />
              </div>
            }
          </div>
          }
        </div>
        <div className='w-100 pl-0' style={styles.mobileHeader.navParent}>
          <Nav
            showNav={showNavigation}
            t={t}
            onOutside={(e) => {
              const clickedOffLogo = e && e.target && e.target.parentElement && (e.target.parentElement.id === 'Layer_2' || e.target.id === 'Angle_Icon')
              this.state.showNavigation && this.setState({showNavigation: false, closedOutside: clickedOffLogo})
            }}
            />
            {
              showSearchTab &&
                <div style={_header.tabs}>
                  <SearchFilters
                    activeContext={searchTab}
                    filters={filters}
                    history={history}
                    isMobile={true}
                  />
                </div>
            }

        </div>
      </div>
    );
  }
}

export const MobileHeader = withTranslation()(connectStream({
  searchTerm: getSearchTerm
})(MobileHeaderComponent));

const setSearchTermInStream = ({target: {value}}) => {
  setTermValue(value);
};


/**
 * Navigation component for mobile header
 *
 * @param showNav
 * @return {XML}
 * @constructor
 */
class NavComponent extends Component {
  handleClickOutside = (e) => {
    this.props.onOutside(e)
  };

  render () {
    const {showNav, t} = this.props
    return (
      <div style={styles.mobileHeader.navContainer(showNav)}>
        {
          isLoggedIn()
          ? <LoggedInLinks isMobile={true}/>
          : <LoggedOutLinks isMobile={true} t={t} />
        }
      </div>
    );
  }
};


export const Nav = withTranslation()(
  onClickOutside(NavComponent)
);

/**
 * Logo component for mobile header. Also opens/hides navigation.
 *
 * @param {function} onClick
 * @param {boolean} isNavOpened
 * @return {XML}
 * @constructor
 */
export const Logo = ({onClick, isNavOpened}) => {
  const AngleIcon = isNavOpened ? UpIcon : DownIcon;
  return (
    <div id={LOGO_ID} onClick={onClick} style={{padding:'8px 0'}}>
      <LogoFlame fill={colors.blue} />
      <AngleIcon id='Angle_Icon' color={colors.blue} style={{paddingTop: 9}}/>
    </div>
  );
};


