import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { connectStream, compose } from '../../lib/bastetjs/utils/connectStream'
import { EventResultsStreams } from '../../data/EventResultsStreams'
import { getTokenRacerId } from '../../utils/isLoggedIn'
import { getAthleteRacesStream } from '../../data/AthleteStreams'
import { useGetEventCoursesQuery } from "../../api/alaskaApi"
import { getFollowersStream } from '../../data/ProfileStreams'
import { getPendingClaims } from '../../data/UnclaimedSearchStreams'
import { getEntryDetail } from '../../data/VRStreams'
import { RtkQueryIndividualResult } from './RtkQueryIndividualResult'
import useEventMetadata from '../../hooks/useEventMetadata'
import { ReigniteIndividualResult } from './ReigniteIndividualResult'
import useReigniteResults from '../../lib/firebase/useReigniteResults'
import { useFirebaseConfig } from '../../lib/firebase/remote-config'

const PureIRPResult = (props: any) => {
  const athlinksEventId = props.event?.athlinksId
  const { data: eventCourses } = useGetEventCoursesQuery(athlinksEventId, {
    skip: !athlinksEventId
  })

  const bib = props.bib
  const entryId = props.entryId
  const eventCourseId = props.race?.eventCourseId
  const isEventLinked = !!props.event?.ctliveId
  const eventMetadata = useEventMetadata(athlinksEventId)
  const usingReigniteResults = useReigniteResults(athlinksEventId, isEventLinked)

  const firebaseConfig = useFirebaseConfig()
  if (!firebaseConfig.initialized) {
    // do not allow app to initialize results-hub if we don't have the remote config yet
    return null
  }

  const isLive = props.isLive || false

  if (usingReigniteResults) {
    return <ReigniteIndividualResult
      {...props}
      athlinksEventId={athlinksEventId}
      bib={bib}
      courses={eventCourses}
      entryId={entryId}
      eventCourseId={eventCourseId}
      eventMetadata={eventMetadata}
      isLive={isLive}
    />
  }

  return <RtkQueryIndividualResult
    {...props}
    athlinksEventId={athlinksEventId}
    courses={eventCourses}
    eventCourseId={eventCourseId}
    eventMetadata={eventMetadata}
    bib={bib}
    entryId={entryId}
    isLive={isLive}
  />
}

const mapStateToProps = (state: any, props: any) => ({
  dispatch: undefined,
  eventMetadata: state.eventMetadata,
  irpResult: state.irpResult?.data || props.irpResult,
  mapTracking: state.mapTracking,
  unitType: state.userPrefs.globalUnitType,
})

export const IRPResult = compose(
  connect(mapStateToProps),
  withTranslation(),
  connectStream({
    friends: () => getFollowersStream('followers', getTokenRacerId()),
    splitsContent: () => EventResultsStreams.getLegacyEntryResult(),
    pendingClaims: getPendingClaims,
    athleteRaces: () => getAthleteRacesStream(getTokenRacerId()),
    entryDetail: ({ irpResult }: any) => getEntryDetail({
      eventCourseId: irpResult.eventCourseId,
      racerId: irpResult.racerId,
      entryUniqueId: irpResult.entryUniqueId,
    }),
  })
)(PureIRPResult)
